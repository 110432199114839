import FeatureBar from '@/components/FeatureBar';
import Link from '@/components/Link';
import useAcceptCookies from '@/hooks/useAcceptCookies';

export default function CookieConsent() {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies();

  if (acceptedCookies) {
    return null;
  }

  return (
    <FeatureBar
      title="Este sitio web utiliza cookies"
      action={
        <div className="flex w-full flex-col space-y-4 md:inline-flex md:w-auto md:flex-row md:items-center md:space-x-3 md:space-y-0">
          <Link
            className="justify-center rounded-full border-2 border-green-500 px-3 py-3 text-center font-semibold leading-4 text-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 md:border-none md:py-2 md:focus:ring-1 md:focus:ring-green-400"
            href="/legal/cookies"
          >
            Política de cookies
          </Link>
          <button
            onClick={onAcceptCookies}
            className="inline-flex justify-center rounded-full bg-green-600 px-5 py-3 text-center font-semibold leading-5 text-white hover:bg-green-500 focus:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-200 md:py-2"
            type="button"
          >
            De acuerdo
          </button>
        </div>
      }
    >
      <div className="font-normal text-gray-700">
        Usamos cookies y otras tecnologías de seguimiento para mejorar su
        experiencia de navegación en nuestro sitio web, mostrarle contenido
        personalizado, analizar el tráfico de nuestro sitio web y comprender de
        dónde provienen nuestros visitantes. Usted acepta nuestro uso de tales
        cookies y herramientas similares si continúa utilizando nuestro sitio.
      </div>
    </FeatureBar>
  );
}
