import PropTypes from 'prop-types';
import clsx from 'clsx';
import Container from '@/components/Container';

const defaultProps = {
  title: null,
  className: 'bg-gradient-to-t from-gray-200 to-white text-gray-900',
  action: null,
};

const propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default function FeatureBar({ title, className, action, children }) {
  const hasTitle = title !== null;
  const hasAction = action !== null;

  return (
    <div
      className={clsx(
        'fixed left-0 right-0 bottom-0 z-50 shadow-outside',
        className
      )}
    >
      <Container>
        <div className="flex w-full flex-col space-y-6 py-4 md:flex-row md:items-center md:space-y-0 md:space-x-10 lg:py-8">
          <div className="flex flex-1 flex-col space-y-2">
            {hasTitle && (
              <h3 className="text-center text-lg font-semibold md:text-left lg:text-xl">
                {title}
              </h3>
            )}
            <div className="hidden text-base md:block">{children}</div>
          </div>
          {hasAction && <div className="flex">{action}</div>}
        </div>
      </Container>
    </div>
  );
}

FeatureBar.defaultProps = defaultProps;
FeatureBar.propTypes = propTypes;
